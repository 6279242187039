@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background-color: $color-black;
	background-image: url('../img/bg/jaz.png'), url('../img/bg/Sunset.jpg');
	background-position: center bottom, center bottom;
	background-repeat: no-repeat, no-repeat;
	background-size: 813px auto, cover;
	font-family: "museo-sans";
	font-size: 16px;
	font-weight: 700;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
}

body {
	align-items: center;
	color: $color-yellow;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: 100vh;
}

a {
	color: $color-yellow;

	@include hover-focus {
		color: $color-yellow;
	}
}

.btn {
	background: none;
	border: 3px solid $color-yellow;
	border-radius: 23px;
	color: $color-yellow;
	display: inline-block;
	font-weight: 700;
	padding: 13px 50px;
	text-align: center;
	text-transform: uppercase;
	transform: scale(1);
	transition: all 200ms linear;
	vertical-align: middle;

	@include hover-focus {
		outline: 0;
		transform: scale(1.05);
	}
}

header {
	flex: 0 0 auto;
	padding: 30px 0;
	width: 100%;

	nav {
		> ul {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				flex: 1 1 auto;
				font-size: 16px;
				font-weight: 900;

				> a {
					display: inline-block;
					vertical-align: middle;
				}

				&:nth-child(1),
				&:nth-child(3) {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;

					&:after {
						background: $color-yellow;
						content: '';
						display: block;
						flex: 1 1 auto;
						height: 3px;
						margin-left: 20px;
					}
				}

				&:nth-child(3) {
					flex-direction: row-reverse;

					&:after {
						margin-left: 0;
						margin-right: 20px;
					}
				}

				&:nth-child(2) {
					align-items: center;
					display: flex;
					flex: 0 0 auto;
					flex-direction: row;
					flex-wrap: nowrap;
					height: 185px;
					justify-content: center;
					min-width: 373px;
					padding: 0 50px;

					@include tablet-down {
						height: auto;
						min-width: 0;
						padding: 0 20px;
					}

					h1 {
						margin: 0 auto;
						position: relative;
						width: 273px;

						@include tablet-down {
							display: none !important;
						}

						> img {
							margin: 0 auto;

							&:nth-child(2) {
								bottom: 0;
								position: absolute;
								right: 0;
								width: 118px;
							}
						}
					}

					a {
						display: none;

						@include tablet-down {
							display: inline-block !important;
						}
					}
				}
			}
		}
	}
}

main {
	flex: 1 1 auto;
	width: 100%;

	section {
		display: none;

		h2 {
			font-size: 29px;
			font-weight: 900;
			letter-spacing: -0.66px;
			margin: -50px 0 80px 0;
			opacity: 0.5;

			@include tablet-down {
				margin: 0 0 40px 0;
			}
		}

		&#home {
			@include tablet-down {
				display: block;
			}

			.logo {
				margin: 0 auto;
				position: relative;
				width: 273px;

				> img {
					margin: 0 auto 30px auto;

					&:nth-child(2) {
						bottom: 0;
						position: absolute;
						right: 0;
						width: 118px;
					}
				}
			}

			@include tablet-up {
				.logo, .btn {
					display: none;
				}
			}
		}

		&#music {
			.music {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0 auto;
				max-width: 1000px;

				@include tablet-down {
					display: block;
				}

				> div {
					flex: 0 0 33.333333%;
					padding: 0 25px;

					@include tablet-down {
						margin: 0 auto;
						max-width: 300px;
						padding: 0;

						+ div {
							margin-top: 30px;
						}
					}

					> img {
						margin: 0 auto 20px auto;
					}

					> a {
						display: block;
					}
				}
			}
		}

		&#videos {
			@include tablet-down {
				margin: 0 -15px;
			}

			.videos {

				.owl-item {
					video {
						margin: 0 auto 20px auto;
					}

					a {
						display: block;
					}

					@include tablet-down {
						&:not(.active) {
							video {
								border-radius: 25px;
								overflow: hidden;
							}
						}
					}
				}
			}
		}
	}
}

footer {
	flex: 0 0 auto;
	padding: 50px 0 5px 0;
	width: 100%;

	.sections {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-bottom: 8px;

		@include tablet-down {
			display: block;
		}

		> div {
			flex: 0 1 271px;

			&:nth-child(2) {
				flex: 1 1 auto;

				@include tablet-down {
					display: none;
				}

				.btn {
					display: block;
					margin: 0 auto;
					max-width: 460px;
				}
			}

			.socials {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: 18px;
					vertical-align: middle;

					+ li {
						margin-left: 15px;
					}

					> a {
						display: inline-block;
						transform: scale(1);
						transition: all 200ms linear;
						vertical-align: middle;

						@include hover-focus {
							transform: scale(1.1);
						}
					}
				}
			}

			form {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				text-align: center;

				@include tablet-down {
					justify-content: center;
					margin-top: 15px;
				}

				input {
					background: none;
					border: 0;
					border-bottom: 3px solid $color-yellow;
					border-radius: 0;
					color: $color-yellow;
					font-size: 11px;
					font-weight: 700;
					letter-spacing: -0.25px;
					margin-right: 5px;
					padding: 0 0 3px 0;
					text-align: center;
					width: 215px;

					@include placeholder {
						color: $color-yellow;
						text-transform: uppercase;
					}

					&:focus {

					}
				}

				button {
					font-size: 11px;
					padding: 4px 9px;
				}
			}
		}
	}

	.copyright {
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: 8px;
		font-weight: normal;
		letter-spacing: 1px;
		line-height: 10px;
		margin: 0 auto;
		max-width: 500px;

		> a {
			display: inline-block;
			margin-bottom: 2px;
			opacity: 1;
			vertical-align: middle;

			> svg {
				height: 12px;
				width: auto;

				path {
					fill: $color-yellow !important;
				}
			}

			@include hover-focus {
				opacity: 0.8;
			}
		}

		> p {
			margin: 0;

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}
